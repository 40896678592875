@import 'src/mixins';

$selected_fill: #556ee6;
$default_fill: #545a6d;

// nav-bar: 10000
// menu: 10001
// menu-background: 10000
// sidebar: 10003-10004
// sidebar-background: 10002

.ui {
  &__layout {
    @include fluid;
    @include col;
  }

  &__nav-bar {
    @include row;
    align-items: center;

    position: sticky;
    top: 0;
    z-index: 10000;

    width: 100vw;
    height: var(--nav-bar-height);
    padding: 0 10px;
    background-color: white;

    border-bottom: 1px solid #F5F5F5;
  }

  &__menu {
    position: absolute;
    top: 0;
    z-index: 10001;
    width: 100vw;
    height: var(--app-height);
    background-color: white;
    padding: var(--nav-bar-height) 20px 20px 20px;
    box-sizing: border-box;
    transition: left 400ms ease-in-out;
    user-select: none;
    overflow-y: auto;

    border-right: 1px solid #F5F5F5;

    @media screen and (max-width: 400px) {
      width: 100vw;
      left: -100vw;
    }

    @media screen and (min-width: 400px) {
      width: 300px;
      left: -300px;
    }

    &_opened {
      left: 0;
    }

    &-item {
      @include row;
      align-items: center;
      position: relative;

      height: 40px;
      width: 100%;
      padding: 5px 10px;
      margin: 4px 0;
      background-color: white;
      border-radius: 8px;

      &:hover {
        background-color: #F5F5F5;
      }

      &_selected > .ui__title {
          color: $selected_fill;
      }

      &_selected > .ui__icon {
        & > svg {
          fill: $selected_fill;
        }
      }
    }

    &-background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10000;
      height: var(--app-height);
    }
  }

  &__badge {
    position: absolute;
    //z-index: 10001;
    align-self: center;
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    padding: 0 5px;

    background-color: #e72828;
    color: white;
    font-size: 13px;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 300ms ease-in-out;

    &_hidden {
      transform: scale(0);
    }

    &_position_right {
      right: 20px;
    }

    &_position_right-right {
      right: 0;
    }

    &_position_right-top {
      top: -6px;
      right: -6px;
    }

    &_size_small {
      min-width: 20px;
      height: 20px;
      border-radius: 10px;
      font-size: 12px;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;

    & > svg {
      fill: $default_fill;
    }
  }

  &__title {
    color: $default_fill;
  }

  &__breadcrumbs {
    padding: 24px;
  }

  &__h1 {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__container {
    @include fluid;
    @include col;

    &_scrollable_vertical {
      @include fluid;
      @include col;
      overflow-y: auto;
    }

    &_scrollable_horizontal {
      @include fluid;
      @include row;
      overflow-x: auto;
    }
  }

  &_direction_row {
    @include row;
  }

  &_centered_row {
    align-items: center;
  }

  &_centered_col {
    justify-content: center;
  }

  &__button {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &:hover {
      background-color: #eaeaea;
    }

    & > svg {
      fill: $default_fill;
    }

    &_selected {
      & > svg {
        fill: $selected_fill;
      }
    }
  }

  &__separator_horizontal {
    margin: 10px;
    height: 1px;
    background-color: #F5F5F5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    & > .title {
      position: absolute;
      font-size: 12px;
      font-weight: 500;
      background-color: white;
      border-radius: 4px;
      color: #d9d9d9;
      padding: 0 4px;
    }
  }

  &__button-group {
    display: flex;
    border-radius: 8px;
    background-color: white;

    & .ui__button {
      min-width: 40px;
      border-radius: 0;
    }

    & .ui__button {
      min-width: 40px;
      flex-grow: 1;
      flex-basis: 0;
      color: #556ee6;

      &_selected {
        color: white;
        background-color: #556ee6;
      }

      & .ui__badge {
        z-index: 10
      }
    }

    & .ui__button:first-child {
      border-radius: 8px 0 0 8px;
    }

    & .ui__button:last-child {
      border-radius: 0 8px 8px 0;
    }

    //&__button {
      //&_selected {
      //  color: white;
      //  background-color: #556ee6;
      //}
      //
      //& > .ui__badge {
      //  z-index: 10
      //}
    //}
  }
}

.item {
  @include row;

  padding: 12px;
  border-radius: 8px;
  cursor: default;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: #F5F5F5;

    & > .download-file {
      background-color: white;
    }
  }

  &__icon {
    @include center;

    width: 48px;
    height: 48px;
    margin-right: 12px;
    padding: 6px;

    & > svg {
      fill: #868686;
    }

    &_small {
      width: 24px;
      height: 24px;
      padding: 2px;
    }
  }

  &__details {
    @include fluid;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    font-weight: 600;
    font-size: 15px;
    color: #495057;

    &_disabled {
      color: #d9d9d9;
    }
  }

  &__subtitle {
    color: #868686;
    font-size: 14px;
  }

  &__button_more {
    @include center;
    width: 24px;

    & > svg {
      fill: #868686;
    }
  }
}

.container-of-items {
  border-radius: 8px;

  &:hover {
    background-color: #F5F5F5;

    & .ui__separator_horizontal {
      background-color: white;
    }
  }

  & > .item {
    &:hover {
      background-color: transparent;
    }
  }

  &.selected {
    background-color: #F5F5F5;
  }
}

.user__avatar {
  border-radius: 24px;

  & > img {
    border-radius: 24px;
  }
}

.waypoint {
  @include col;
}

.field {
  margin-bottom: 4px;

  &__title {
    color: #868686;
    font-size: 14px;
    margin-right: 12px;
  }

  &__value {
    font-weight: 600;
    font-size: 15px;
    color: #495057;
  }
}

.field-group {
  &__badge {
    margin-bottom: 6px;
  }

  & > .field:last-child {
    margin-bottom: 0;
  }

  & > .field:only-child {
    margin-bottom: 0;
  }
}

.parcel {
  display: flex;
  margin-bottom: 8px;
  padding: 6px 12px;
  border-radius: 16px;
  align-items: center;

  &__designation {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    color: rgba(246, 246, 246, 1);
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #556ee6;
  }

  &__description {
    flex-grow: 1;
    flex-basis: 0;
    padding: 6px 12px;
  }
}

.no-internet-connection {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .7);
  z-index: 1000000000;
  display: flex;
  align-items: center;
  justify-content: center;

  &__title {
    color: white;
    font-size: 18px;
    font-weight: bold;
  }
}

.sidebar {
  position: absolute;
  z-index: 10003;
  top: 0;

  height: var(--app-height);

  background: white;
  overflow-y: auto;

  border-left: 1px solid #F5F5F5;

  @media screen and (max-width: 400px) {
    width: 100vw;
  }

  @media screen and (min-width: 400px) {
    width: var(--sidebar-width);
    max-width: 100vw;
  }

  &__background {
    width: 100vw;
    height: var(--app-height);
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10002;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 10000;
    height: var(--nav-bar-height);
    background-color: white;
    border-bottom-width: 1px;
    border-bottom-color: #F5F5F5;
    border-bottom-style: solid;
    padding: 10px;
    box-sizing: border-box;
  }
}

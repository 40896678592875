@mixin fluid {
  flex-grow: 1;
  flex-basis: 0;
}

@mixin col {
  display: flex;
  flex-direction: column;
}

@mixin row {
  display: flex;
  flex-direction: row;
}

@mixin center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

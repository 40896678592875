.dashboard-card {
  &.bg_green {
    background-color: #7dd52e;
  }

  &.bg_blue {
    background-color: #2D9BF0;
  }

  &.bg_red {
    background-color: #F24726;
  }

  &__title {
    color: #ffffff;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
  }

  &__value {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  &__value {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  &__percentages {
    color: #ffffff;
    font-size: 18px;
  }
}

.dashboard-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  background-color: transparent;
  border: none;
  //margin: 0 0 0 -3px;
  padding: 0;
  width: 100%;
  font-family: inherit;
  font-size: 13px;
  color: #ffffff;
  cursor: inherit;
  box-sizing: border-box;
}

.dashboard-select-icon {
  width: 16px;
  height: 16px;
}

.dashboard-bar-chart {
  min-height: 25vw;
  display: flex;
  align-items: center
}

.dashboard-pie-chart {
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    min-height: 25vw;
    min-width: 200px;
    max-width: 25vw;
    width: 25vw;
  }
}

.fill-black > svg {
  fill: black
}

@media screen and (min-width: 400px) {
  @keyframes slide-right-in {
    from {right: calc(-1 * var(--sidebar-width))}
    to {right: 0}
  }

  @keyframes slide-right-out {
    from {right: 0}
    to {right: calc(-1 * var(--sidebar-width))}
  }
}

@media screen and (max-width: 400px) {
  @keyframes slide-right-in {
    from {right: -100vw}
    to {right: 0}
  }

  @keyframes slide-right-out {
    from {right: 0}
    to {right: -100vw}
  }
}

@keyframes slide-bottom-in {
  from {top: 100vh}
  to {top: 0}
}

@keyframes slide-bottom-out {
  from {top: 0}
  to {top: 100vh}
}


@keyframes fade-in {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fade-out {
  from {opacity: 1}
  to {opacity: 0}
}

.animation_slide-right-in {
  animation: 400ms ease-in-out slide-right-in both;
}

.animation_slide-right-out {
  animation: 400ms ease-in-out slide-right-out both;
}

.animation_slide-bottom-in {
  animation: 300ms ease-in-out slide-bottom-in both;
}

.animation_slide-bottom-out {
  animation: 300ms ease-in-out slide-bottom-out both;
}

.animation_fade-in {
  animation: 250ms ease-in-out fade-in both;
}

.animation_fade-out {
  animation: 250ms ease-in-out fade-out both;
}

.chats-page {
  box-sizing: border-box;
  padding-bottom: 24px;

  display: flex;
  flex-direction: row;

  height: var(--page-height);

  &__left-sidebar {
    display: flex;
    flex-direction: column;

    height: var(--page-height);
    padding: 0 15px;
    margin-left: 0;

    transition: margin-left 300ms ease-in-out;

    @media screen and (max-width: 600px) {
      width: 100vw;
      min-width: 100vw;
    }

    &_can-be-hidden {
      @media screen and (max-width: 600px) {
        margin-left: -100vw;
      }
    }

    @media screen and (min-width: 600px) {
      width: 350px;
      max-width: 350px;
      margin-right: 15px;
    }
  }
}

.chat-list {
  //margin-right: 15px;
  padding-bottom: 24px;
  box-sizing: border-box;

  &__header {
    display: flex;
    //margin-right: 16px;
    margin-bottom: 16px;
    background-color: white;
    border-radius: 4px;
  }

  &__button {
    position: relative;
    border-radius: 8px;
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    color: #556ee6;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &_selected {
      color: white;
      background-color: #556ee6;
    }

    & > .ui__badge {
      z-index: 10
    }
  }
}

.chat {
  display: flex;
  flex-direction: column;

  padding: 12px 16px;
  box-sizing: content-box;
  list-style: none;
  //height: 40px;

  cursor: default;

  &:hover {
    cursor: default;
  }

  &.selected {
    background-color: #fff;
    border-radius: 4px;
  }

  &__content {
    flex-grow: 1;
    flex-basis: 0;

    display: flex;
    align-items: center;
  }

  &__avatar {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    border-radius: 20px;
    background-color: #F1F1F1;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &_user-photo {
      border-radius: 20px;
    }

    &_short-name {
      font-size: 13px;
      color: #74788d;
      font-weight: 600;
    }
  }

  &__info {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__order-unique-code {
    font-weight: 500;
    color: #556ee6;
    font-size: 13px;
  }

  &__user-name {
    font-weight: 500;
    color: #495057;
    font-size: 14px;
  }

  &__user-status {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    right: -3px;
    bottom: -3px;
    border: 2px solid white;

    &_online {
      background-color: rgb(52,195,143);
    }
  }

  &__last-message {
    &-text {
      flex-grow: 1;
      flex-basis: 0;
      width: 200px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      color: #74788d;
      font-size: 14px;
    }

    &-time {
      margin-left: 14px;
      color: #74788d;
      font-size: 12px;
    }
  }

  &__footer {
    display: flex;

    margin-top: 5px;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 0 6px 0 0;
    border-radius: 2px;

    transition: background-color 150ms ease-in-out;

    &:hover {
      background-color: #556ee6;

      & > .chat__button-icon > svg {
        fill: white;
      }

      & > .chat__button-icon > .spinner-border {
        color: white;
      }

      & > .chat__button-title {
        color: white;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: 24px;
      padding: 4px;

      & > svg {
        fill: #bbbbbb;
      }

      & > .spinner-border {
        color: #556ee6;
      }
    }

    &-title {
      font-size: 12px;
      color: #74788d;

      margin-left: 3px;
    }
  }
}

.unread-message-counter {
  position: absolute;
  z-index: 100000;
  right: 0;
  min-width: 20px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px;
  background-color: #556ee6;
  border-radius: 25px;
  color: white;
  font-size: 11px;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;

  &_open {
    transform: scale(1);
  }

  &_color_red {
    background-color: #ff3c3c;
  }

  &_pos_right_top {
    right: -6px;
    top: -6px;
  }
}

.chat-room {
  word-wrap: break-word;
  background-color: #fff;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 15px;

  @media screen and (max-width: 600px) {
    width: calc(100vw - 30px);
    min-width: calc(100vw - 30px);
  }

  &__header {
    border-radius: 0.25rem 0.25rem 0 0;
    background-color: #fff;
    padding: 14px 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eff2f7;
    height: 40px;
    box-sizing: content-box;
  }

  &__user-name {
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    font-weight: 500;
    color: #495057;
    font-size: 15px;
  }

  &__footer {
    padding: 16px;
    box-sizing: border-box;
    border-top: 1px solid #eff2f7;
  }

  &__input {
    width: 100%;
    appearance: none;
    background-clip: padding-box;
    background-color: #eff2f7;
    border: 1px solid #eff2f7;
    color: #495057;
    display: block;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.47rem 0.75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 30px;
    margin-right: 30px;
  }

  &__send-message-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    border-radius: 30px;
    background-color: #556ee6;
    color: #fff;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.47rem 0.75rem;
    text-align: center;
    border: 1px solid #556ee6;
    user-select: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:hover {
      background-color: #3d56c9;
    }

    & > span:nth-child(1) {
      margin-right: 10px;
    }
  }
}

.message-list {
  flex-grow: 1;
  flex-basis: 0;
  display: inline-flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  justify-content: flex-end;
}

.message {
  margin-bottom: 12px;
  min-width: 100px;

  &_side_left {
    align-self: flex-start;
    background-color: rgba(85,110,230,.1);
    border-radius: 8px 8px 8px 0;
    padding: 8px 12px;

    //& > .message__footer > .message__status_sending {
    //  display: none;
    //}
  }

  &_side_right {
    align-self: flex-end;
    background-color: #eff2f7;
    border-radius: 8px 8px 0 8px;
    text-align: right;
    padding: 8px 12px;

    & > .message__user-name {
      display: none;
    }
  }

  &__user-name {
    color: #556ee6;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &__text-content {
    margin-bottom: 8px;
    color: #495057;
    text-align: left;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__time {
    color: #666f74;
    font-size: 11px;
    font-style: italic;
  }

  &__status_sending {
    margin-left: 4px;

    & > svg {
      width: 16px;
      height: 16px;
      fill: #666f74;
    }
  }
}

.map-page {
  &__map {
    height: var(--layout-height);
  }

  &__sidebar_left {
    position: absolute;
    top: var(--nav-bar-height);
    height: var(--layout-height);
    background-color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    transition: left 500ms ease-in-out;

    @media screen and (max-width: 400px) {
      width: calc(100vw - 40px);
    }

    @media screen and (min-width: 400px) {
      width: 300px;
      max-width: calc(100vw - 40px);
    }
  }

  &__btn_toggle-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 40px;
    right: -40px;
    width: 40px;
    height: 40px;
    background-color: white;

    box-shadow: 1px 0 2px 0 rgba(0,0,0,0.05);
    -webkit-box-shadow: 1px 0 2px 0 rgba(0,0,0,0.05);

    & > svg {
      transition: transform 500ms ease-in-out;
      width: 30px;
      height: 30px;
    }

    &.rotate_left {
      & > svg {
        transform: rotate(-180deg);
      }
    }
  }
}

:root {
  --app-height: 100%;
  --nav-bar-height: 61px;
  --sidebar-width: 360px;
  --breadcrums-height: 72px;
  --layout-height: calc(var(--app-height) - var(--nav-bar-height));
  --page-height: calc(var(--layout-height) - var(--breadcrums-height));
}

body {
  height: var(--app-height);
  //overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: var(--app-height);
}

.btn_back {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon {
    height: 40px;
    width: 20px;
    padding: 8px 0;

    & > svg {
      fill: #495057;
    }
  }

  &:hover > &__icon > svg {
    fill: #556ee6;
  }

  &__title {
    color: #495057;
  }

  &:hover > &__title {
    color: #556ee6;
  }
}

.ui__modal {
  z-index: 100001;

  &_backdrop {
    z-index: 100000;
  }
}
